import cx from "classnames"
import { FastField, Field, Form, useTranslation, withFormik } from "formik"
import React, { useState, useContext, useEffect } from "react"
import * as Yup from "yup"
import { AffiliateContext } from "../../contexts/AffiliateContext"
import { getCookie, getReferrer, getUTM } from "../../helpers"
import { Button } from "../button"
import styles from "./form.module.css"
import {
  PhoneErrorMessage,
  Terms2ErrorRequiredMessage,
  TermsErrorRequiredMessage,
} from "./forms-error-messages"
import mainFormStyles from "./main-form.module.css"

// import MaskedInput from 'react-text-mask';
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 9 znaků", value =>
    rePhoneNumber.test(value)
  )
})

export const OrderConsultationFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  className,
}) => {
  const [checkTerms, setCheckTerms] = useState(false)
  const [checkTerms2, setCheckTerms2] = useState(false)

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate, affiliateId } = affiliateContext.affiliate

  const handleChange = () => {
    setFieldValue("isAffiliate", isAffiliate)
    setFieldValue("affiliateId", affiliateId)
  }

  useEffect(() => {
    handleChange()
  }, [affiliateContext, isAffiliate, affiliateId])

  return (
    <Form
      className={cx(mainFormStyles.mainForm, mainFormStyles.heroForm)}
      name="menu-formular"
      method="post"
    >
      <div className={styles.inputField}>
        <label className={styles.label}>Telefon *</label>
        <FastField
          component="input"
          type="text"
          name="phone"
          className={styles.input}
        />
        {touched.phone && errors.phone && (
          <span className={styles.error}>{errors.phone}</span>
        )}
      </div>
      <div className={styles.inputField}>
        <label htmlFor="promo" className={styles.label}>
          Promo kód
        </label>
        <FastField
          component="input"
          type="text"
          name="promo"
          className={styles.input}
        />
        {touched.promo && errors.promo && (
          <span className={styles.error}>{errors.promo}</span>
        )}
      </div>
      <div className={mainFormStyles.checkTerms}>
        <input
          id="checkTerms"
          type="checkbox"
          name="checkTerms"
          checked={checkTerms}
          onChange={e => {
            setCheckTerms(e.target.checked)
            console.log(e.target.checked)
          }}
        />
        <label htmlFor="checkTerms">
          Měl/a jsem možnost přečíst a souhlasím s{" "}
          <a href="/terms" target="_blank">
            <b>obchodními podmínkámi.</b>
          </a>
        </label>
      </div>

      <div className={mainFormStyles.checkTerms}>
        <input
          id="checkTerms2"
          type="checkbox"
          name="checkTerms2"
          checked={checkTerms2}
          onChange={e => {
            setCheckTerms2(e.target.checked)
          }}
        />
        <label htmlFor="checkTerms2">
          Souhlasím se zpracováním osobních údajů.
        </label>
      </div>
      <div className={mainFormStyles.buttons}>
        <Button
          name="submit"
          type="primary"
          buttonType="submit"
          disabled={isSubmitting || !checkTerms || !checkTerms2}
          className={mainFormStyles.submitButton}
        >
          Zavolejte mi
        </Button>
      </div>
      <Field
        component="input"
        type="hidden"
        name="isAffiliate"
        value={isAffiliate}
      />
      <Field
        component="input"
        type="hidden"
        name="affiliateId"
        value={affiliateId}
      />
    </Form>
  )
}

export const OrderConsultationForm = withFormik({
  mapPropsToValues: () => ({
    phone: "+420",
    promo: "",
    referrer: "",
    ga: "",
    success: false,
    isAffiliate: false,
    affiliateId: null,
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(9, <PhoneErrorMessage />)
        .phone(),
      promo: Yup.string(),
      checkTerms: Yup.bool().oneOf([true], <TermsErrorRequiredMessage />),
      checkTerms2: Yup.bool().oneOf([true], <Terms2ErrorRequiredMessage />),
    }),
  handleSubmit: async (
    { phone, promo, isAffiliate, affiliateId },
    { props, setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      let data = {
        form_name: "order-menu-formular",
        language: isEn ? "en" : "cs",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone,
        promo,
        cjevent: affiliateId,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
      }

      if (props.leadName !== undefined) {
        data = { ...data, [props.leadName]: props.leadValue }
      }

      // await console.log("data", data)

      const isEn = document.location.pathname.includes("/en")

      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/application`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })

      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)

        const response = await req.json()

        if (isAffiliate) {
          const getParamsData = Object.entries(response)
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          window.location.href = isEn
            ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
            : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
        } else {
          window.location.href = isEn ? "/en/thank-you" : "/thank-you"
        }

        setTimeout(() => {
          resetForm()
          window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn ? "/en/thank-you" : "/thank-you",
            pageType: "Purchase",
          })
        }, 300)
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(OrderConsultationFormLayout)
